import React, { memo } from 'react';
import CountUp from 'react-countup';
import useWindowDimensions from '../../Helpers/useWindowDimensions';
import { useSelector } from 'react-redux';



const BannerSection = memo((props) => {

    const { windowWidth } = useWindowDimensions();

    const users = props?.users?.toString().split("");
    
    return (
        <div className="banner" style={{ top: '0px !important', marginTop: '-2px' }}>
            {windowWidth > 781 ?
                <video playsInline preload="auto"  autoPlay muted loop src={`${process.env.REACT_APP_URL}/assets/homepage/home_banner_video_new.webm`} poster={`${process.env.REACT_APP_URL}/assets/homepage/HomepageVideoThumbnails/banner-Desktop.webp`}></video> :
                // <video preload="auto" class="d-flex" autoPlay muted loop src="public/assets/homepage/HomepageVideoThumbnails/winter-bg.webp" playsInline poster={`/assets/homepage/HomepageVideoThumbnails/winter-bg.webp`}></video> :
                // <video preload="auto" class="d-flex" autoPlay muted loop src="/assets/homepage/HomepageVideoThumbnails/winter-mob.webp" playsInline poster={`/assets/homepage/HomepageVideoThumbnails/winter-mob.webp`}></video>
                <video preload="auto"  autoPlay muted loop src={`${process.env.REACT_APP_URL}/assets/homepage/mobile-banner.webm`} playsInline poster={`${process.env.REACT_APP_URL}/assets/homepage/HomepageVideoThumbnails/banner-Mobile.webp`}></video>

            }

            <div className="overlay">
                <div className="banner-txt">
                    <h1>Best Online Music <br/> Learning Platform</h1>
                    <p style={{ marginBottom: '1rem' }}>Start learning for free</p>
                    <button style={{ cursor: 'pointer' }} target='_blank' onClick={props?.handlePersonalization}>Get Started</button>

                    <div className="Active-Learners mt-5">
                        <div className="d-flex gap-2">
                            {users?.length > 0 && users.map((digit, index) => (
                                <div className="count" key={index}>
                                    <p>
                                        <CountUp
                                            start={0}
                                            end={digit}
                                            duration={3}
                                            delay={index * 0.7}
                                        />
                                    </p>
                                </div>
                            ))}
                        </div>
                        <h6>Active Learners</h6>
                    </div>
                </div>
            </div>

            {/* <div className='winter-sale overlay'>
                <h2>Winter SALE is LIVE</h2>
                <p>Master your favourite instrument with a additional 35% off</p>
                <button style={{ cursor: 'pointer' }} target='_blank'  onClick={props?.handlePersonalization}>Start Learning</button>
            </div> */}
        </div>
    );
});

export default BannerSection;
