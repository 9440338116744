import React, { useEffect, useState } from 'react'
import './FeaturedBlogs.scss'
import { blogsService } from '../../../Adapters/blog.service';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { blogsAction } from '../../../Action/blog.actions';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import useWindowDimensions from '../../../Helpers/useWindowDimensions';


const FeaturedBlogs = React.memo(({ isMainCOursePage }) => {

    const authState = useSelector((state) => state.auth);
    const { viewsData, } = useSelector((state) => state.blogs);

    const dispatch = useDispatch();

    const [featuredData, setFeaturedData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const { windowWidth } = useWindowDimensions();


    useEffect(() => {
        if (isMainCOursePage) {
            getData()
            dispatch(blogsAction.getBlogsView());

        }

    }, [isMainCOursePage])

    const getSlug = (url, id) => {
        if (url && id) {
            return url + '-' + id;
        }
    };

    const truncateToWords = (text, limit) => {
        const plainText = text?.replace(/<[^>]*>/g, "");
        const words = plainText?.split(/\s+/);
        const truncatedText = words?.slice(0, limit).join(" ");
        return truncatedText + (words?.length > limit ? "..." : "");
    };
    

    const handleNavigate = (id) => {
        const userId = authState?.streak?.user_data?.id;
        const payload = { post_id: id, user_id: authState?.auth ? userId : 0 };
        dispatch(blogsAction.postBlogView(payload));
    }


    const formatViewsCount = (count) => {
        return count > 1000 ? `${(count / 1000).toFixed(1)}k` : count;
    };

    const getData = async () => {
        setIsLoading(true)
        try {
            const data = await blogsService.getBlogsData(`${process.env.REACT_APP_BlOGS_URL}/tags?slug=featured`);
            if (data?.status === 200) {

                if (data?.data[0]) {
                    const featuredpost = await blogsService.getBlogsData(`${process.env.REACT_APP_BlOGS_URL}/posts?tags=${data?.data[0]?.id}`);
                    if (featuredpost?.status === 200) {
                        setFeaturedData(featuredpost?.data)
                        setIsLoading(false)
                    }

                }
            } else {
            }
        } catch (err) {
            console.error('Error:', err);
        } finally {
            setIsLoading(false)

        }
    };
    return (
        <div className='featured-blogs'>
            {featuredData?.length > 0 && !isLoading &&
                <>
                    <h3>Learn about music</h3>

                    <Swiper
                        modules={[Pagination, Navigation]}
                        slidesPerView={2}
                        spaceBetween={10}
                        grabCursor={true}
                        loop={true}
                        pagination={{ clickable: true }}
                        navigation={{
                            nextEl: '.button-next',
                            prevEl: '.button-prev',
                        }} breakpoints={{
                            320: { slidesPerView: 1, spaceBetween: 20 },
                            480: { slidesPerView: 1, spaceBetween: 30 },
                            640: { slidesPerView: 1, spaceBetween: 40 },
                            1000: { slidesPerView: 2, spaceBetween: 40 },
                            1200: { slidesPerView: 4, spaceBetween: 40 },
                        }}
                        className=""
                    >{featuredData?.slice(0, 6).map((item, index) => (
                        <SwiperSlide key={index}>
                            <Link to={`/blogs/${getSlug(item?.slug, item?.id)}`} onClick={() => handleNavigate(item?.id)}>
                                <div className="blog-card small-card d-flex">
                                    <div className="media">
                                        <img
                                            style={{
                                                aspectRatio: "10 / 7",
                                                minHeight: "157px",
                                                minWidth: "220px",
                                            }}
                                            src={isLoaded ? (item?.fimg_url && item?.fimg_url) : '/assets/img/greybg.jpeg'}
                                            alt=""
                                            onLoad={() => { setIsLoaded(true) }}
                                        />

                                    </div>
                                    <div className="content">
                                        <h2 className="blog-title" >
                                            {item?.title?.rendered}
                                        </h2>
                                        <p
                                            className=""
                                            dangerouslySetInnerHTML={{
                                                __html:  item?.excerpt?.rendered ? truncateToWords(item?.excerpt?.rendered ,20 ) : "",
                                            }}
                                        />
                                        <div className="time-and-viewer">
                                            <ul key={index}>
                                                <li>{item?.post_reading_time}</li>
                                                {/* {
                                                    viewsData && viewsData?.data?.filter((data => data.post_id == item?.id))?.map((views, idx) => (
                                                        <li><i className="bi bi-eye" aria-hidden="true" style={{ fontSize: '15px', top: 0 }} ></i> Views : {formatViewsCount(views?.view_count)} </li>
                                                    ))
                                                } */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))

                        }
                        <div className="button-prev">
                            <img src="/assets/img/learning/left-arrow.svg" alt="Previous" />
                        </div>

                        <div className="button-next">
                            <img src="/assets/img/learning/right-arrow.svg" alt="Next" />
                        </div>

                    </Swiper>

                    <Link to={'/blogs'} className='btn'>View All</Link>

                </>

            }
            <div
                className="course-details course-list"
                style={{
                    display: 'grid',
                    gridTemplateColumns: windowWidth > 991 ? 'repeat(4, 1fr)' :'',
                    gap: '16px',
                }}
            >
                {isLoading &&
                    Array.from({ length: 4 }).map((_, index) => (
                        <Box key={index} sx={{ margin: '16px', width: '90%', margin:'0px auto' }}>
                            <Skeleton variant="rectangular" width="100%" height={200} />
                            <Skeleton width="80%" height={30} sx={{ mt: 1 }} />
                            <Skeleton width="60%" height={20} sx={{ mt: 1 }} />
                            <Box className="d-flex justify-content-between gap-5">
                                <Skeleton width="40%" height={30} sx={{ mt: 1 }} />
                                <Skeleton width="40%" height={30} sx={{ mt: 1 }} />
                            </Box>
                        </Box>
                    ))
                }
            </div>


        </div>
    )
});

export default FeaturedBlogs