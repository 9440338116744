import { blogsService } from "../Adapters/blog.service";
import { blogsConstants } from "../Constants/blogsConstant";

export const blogsAction = {
    getBlogs,
    getFeaturedBlogs,
    getAllTags,
    getAllCategories,
    getCategoryTabData,
    getAuthorDetails,
    postBlogView,
    getBlogsView,
    getSimilarBlogsData,
    getRecommendedCoursesBlogs,
    getChildCategorires,
    getSubChildCategorires

}


function getBlogs(id) {
    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/posts/${id}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                   
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_BLOGS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_BLOGS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_BLOGS_SUCCESS, payload: data };
    }
}

function getFeaturedBlogs(id) {
    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/posts?tags=${id}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_FEATURED_BLOGS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_FEATURED_BLOGS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_FEATURED_BLOGS_SUCCESS, payload: data };
    }
}


function getAllTags() {

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/tags?per_page=100&page=1`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_TAGS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_TAGS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_TAGS_SUCCESS, payload: data };
    }
}


function getAllCategories() {

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/categories?per_page=100&page=1`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_CATEGORIES_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_CATEGORIES_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_CATEGORIES_SUCCESS, payload: data };
    }
}


function getCategoryTabData(id, per_page = 100, page = 1) {

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/posts?categories=${id}&per_page=${per_page}&page=${page}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data, response?.headers));
                } else {
                    dispatch(errorsBlogs(response.statusText))
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_CATEGORY_TABS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_CATEGORY_TABS_PENDING };
    }

    function success(data, headers) {
        return { type: blogsConstants.GET_CATEGORY_TABS_SUCCESS, payload: { data, headers } };
    }
}



function getAuthorDetails(id) {

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/users/${id}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_AUTHOR_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_AUTHOR_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_AUTHOR_SUCESS, payload: data };
    }
}



async function postBlogView(payload) {
    let url = new URL(`${process.env.REACT_APP_BlOGS_REST_API_URL}/addViews`);
    return (dispatch) => {
        dispatch(request());
        blogsService.postBlogsData(url.href, payload)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.ADD_BLOGS_VIEWS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.ADD_BLOGS_VIEWS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.ADD_BLOGS_VIEWS_SUCCESS, payload: data };
    }
}



function getBlogsView() {

    let url = new URL(`${process.env.REACT_APP_BlOGS_REST_API_URL}/getViews`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_BLOGS_VIEWS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_BLOGS_VIEWS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_BLOGS_VIEWS_SUCCESS, payload: data };
    }
}

function getSimilarBlogsData(ids) {

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/posts?tags=${ids}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_SIMILAR_BLOGS_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_SIMILAR_BLOGS_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_SIMILAR_BLOGS_SUCESS, payload: data };
    }
}


function getRecommendedCoursesBlogs(params, headers) {
    let url = new URL(`${process.env.REACT_APP_API_URL}/api/recommended-courses`);

    return (dispatch) => {
        dispatch(request());
        blogsService
        .getBlogsData(url.href)
        .then((response) => {
            dispatch(success(response?.data?.data));
        })
        .catch((error) => {
            dispatch(errorRecommendedCourses(error));
        });
    };
  
    function errorRecommendedCourses(error) {
        return { type: blogsConstants.GET_RECOMENDED_COURSES_FAILURE, error: error };
    }
  
    function request() {
        return { type: blogsConstants.GET_RECOMENDED_COURSES_PENDING };
    }
  
    function success(data) {
        return { type: blogsConstants.GET_RECOMENDED_COURSES_SUCESS, payload: data };
    }
}

function getChildCategorires(id){
  

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/categories?parent=${id}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_CHILD_CATEGORIES_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_CHILD_CATEGORIES_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_CHILD_CATEGORIES_SUCCESS, payload: data };
    }
}


function getSubChildCategorires(id){
  

    let url = new URL(`${process.env.REACT_APP_BlOGS_URL}/categories?parent=${id}`);
    return (dispatch) => {
        dispatch(request());
        blogsService.getBlogsData(url.href)
            .then((response) => {
                if (response.status == 200) {
                    dispatch(success(response?.data));
                } else {
                }
            })
            .catch((error) => {
                dispatch(errorsBlogs(error));
            });
    };

    function errorsBlogs(error) {
        return { type: blogsConstants.GET_SUBCHILD_CATEGORIES_FAILURE, error: error };
    }

    function request() {
        return { type: blogsConstants.GET_SUBCHILD_CATEGORIES_PENDING };
    }

    function success(data) {
        return { type: blogsConstants.GET_SUBCHILD_CATEGORIES_SUCCESS, payload: data };
    }
}