import { post, get ,getData, postData } from "./xhr";

export const blogsService = {
  getBlogsData,
  postBlogsData
    
};

function getBlogsData(url, headers) {

  return getData(url, {'Authorization':headers});
}


function postBlogsData(url, payload){

  return  postData(url, payload);

}




